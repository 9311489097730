import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
//Components
import { AnchorLink } from "gatsby-plugin-anchor-links";


// markup
const SiteMapPage = ({ data }) => {
  return (
    <Layout addedClass="page--sitemap">
      <DocumentHeadMeta title='Sitemap | ALL IN&reg; for Lupus Nephritis' description='' robots="noindex" canonicalURL='https://www.allinforlupusnephritis.com/sitemap' />
      <section className="bg-white">
        <div className="container container--narrow">
            <h1>Sitemap</h1>
            <ul className="standard-list">
                <li>
                    <AnchorLink to="/">Home</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/about-lupus-nephritis">About LN</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/diagnosis-and-treatment">Diagnosis and Treatment</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/managing-lupus-nephritis">Care for Your Health</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/resources">Resources</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/personal-stories">Personal Stories</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/register"> Get Your Kit</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/contact">Contact Us</AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/unsubscribe">Unsubscribe</AnchorLink>
                </li>
            </ul>
        </div>
      </section>    
    </Layout>
  )
}

export default SiteMapPage